<template>
    <div v-if="isShow" class="fixed top-0 right-0 left-0 bottom-0 z-50 px-4 min-h-screen md:flex md:items-center md:justify-center">
        <div class="bg-black opacity-25 w-full h-full absolute z-10 inset-0"></div>
        <div class="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative">
            <div class="flex align-middle items-center">
                <svg class="animate-spin ml-1 mr-3 h-10 w-10 text-gray-600 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <p>Mohon menunggu beberapa saat...</p>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'LoadingModal',
    props: {
        isShow : {
            type : Boolean,
            default : false
        }
    }
}
</script>
